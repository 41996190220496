class StickyHeader {
    constructor(shopifyHeader){
        this.shopifyHeader = shopifyHeader
        this.currentScrollPos = window.pageYOffset;
        this.prevScrollPos = window.pageYOffset;
        window.addEventListener('scroll', this.scrollHandler);
    }

    showHeader() {
        this.shopifyHeader.setAttribute('data-state', 'visible');
    }

    shouldShowHeader() {
        return this.prevScrollPos > this.currentScrollPos && this.currentScrollPos >= 300;
    }

    shouldHideHeader() {
        return this.currentScrollPos === 0;
    }

    hideHeader(){
        this.shopifyHeader.setAttribute('data-state', 'auto');
    }

    scrollHandler = e => {
        this.currentScrollPos = window.pageYOffset;

        if (this.shouldShowHeader()) {
            this.showHeader();
        } else if (this.shouldHideHeader()) {
            this.hideHeader();
        } else {
            setTimeout(() => {
                this.shopifyHeader.setAttribute('data-state', 'hidden');

                const megaMenuItems = document.querySelectorAll('[data-megamenu-item]');
                const underlay = document.querySelector('[data-underlay]');

                megaMenuItems.forEach(element => element.setAttribute('aria-expanded', 'false'));
                underlay.setAttribute('data-blur', 'false');
                underlay.setAttribute('data-state', 'hidden');
            }, 100);
        }

        this.prevScrollPos = this.currentScrollPos;
    }
}

export default StickyHeader;
